import React from 'react'
import BaseWidget, { BaseWidgetProps } from './BaseWidget'
import { useTopWinners } from '../../lib/bets'
import dayjs from 'dayjs'
import CountryIcon from '../core/CountryIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoinFront, faCrown } from '@fortawesome/pro-solid-svg-icons'
import Image from 'next/legacy/image'

const startDate = dayjs(new Date()).add(-7, 'days').format('YYYY-MM-DD')
const endDate = dayjs(new Date()).format('YYYY-MM-DD')

type RankingWidgetProps = BaseWidgetProps & {}
const RankingWidget = (props: RankingWidgetProps) => {
  const { topWinners } = useTopWinners(0, 10, startDate, endDate)

  const [firstWinner, secondWinner, thirdWinner, ...othersWinners] = topWinners ? topWinners : []

  const FirstWinnerItem = ({ firstWinner }: { firstWinner: any }) => {
    return (
      <div className={'top-winners-widget-item flex justify-between flex-col items-center w-full self-start mb-8 '}>
        <div className={'top-winners-widget-item-info flex flex-col space-x-2 items-center'}>
          <div className={'top-winners-widget-item-profile flex justify-center items-center flex-col'}>
            <div className={'top-winners-widget-item-profile-crown relative flex justify-center items-center -mb-3'}>
              <FontAwesomeIcon className={'text-amber-400 w-10 h-10'} icon={faCrown} />
            </div>
            <div className={'top-winners-widget-item-profile-country bg-yellow-400 rounded-full p-1 w-24 h-24'}>
              <CountryIcon countryCode={firstWinner.countryISOCode} />
            </div>
            <div
              className={
                'top-winners-widget-item-profile-medal relative w-8 h-8 bg-amber-400 rounded-full flex justify-center items-center text-center p-2 -mt-4'
              }
            >
              <span className={'top-winners-widget-item-profile-text text-center text-lg font-medium text-white'}>
                1
              </span>
            </div>
          </div>
          <div className={'flex justify-center items-center'}>
            <span
              className={
                'top-winners-widget-item-name text-lg text-center font-semibold capitalize w-20 whitespace-nowrap overflow-hidden text-ellipsis'
              }
            >
              {firstWinner.firstName}
            </span>
          </div>
          <div className={'top-winners-widget-item-gain flex justify-center items-center'}>
            <FontAwesomeIcon
              className={'top-winners-widget-item-gain-icon text-amber-400 mr-1 size-4'}
              icon={faCoinFront}
            />
            <span className={'top-winners-widget-item-gain-text text-center align-middle'}>
              {Math.trunc(firstWinner.gain) + ' pts'}
            </span>
          </div>
        </div>
      </div>
    )
  }

  const SecondWinnerItem = ({ secondWinner }: { secondWinner: any }) => {
    return (
      <div className={'top-winners-widget-item flex justify-between flex-col items-center w-full px-4 py-2'}>
        <div className={'top-winners-widget-item-info flex flex-col space-x-2 items-center '}>
          <div className={'top-winners-widget-item-profile flex justify-center items-center flex-col'}>
            <div className={'top-winners-widget-item-profile-country relative bg-slate-300 rounded-full p-1 w-16 h-16'}>
              <CountryIcon countryCode={secondWinner.countryISOCode} />
            </div>
            <div
              className={
                'top-winners-widget-item-profile-medal relative w-8 h-8 bg-slate-300 rounded-full flex justify-center items-center text-center p-2 -mt-4'
              }
            >
              <span className={'top-winners-widget-item-profile-text text-center text-white text-lg font-medium'}>
                2
              </span>
            </div>
          </div>
        </div>
        <div className={'top-winners-widget-item-details flex justify-between flex-col items-center'}>
          <span
            className={
              'top-winners-widget-item-name text-lg text-center font-semibold capitalize block w-24 whitespace-nowrap overflow-hidden text-ellipsis'
            }
          >
            {secondWinner.firstName}
          </span>
          <div className={'top-winners-widget-item-gain flex justify-center items-center'}>
            <FontAwesomeIcon
              className={'top-winners-widget-item-gain-icon text-amber-400 mr-1 size-4'}
              icon={faCoinFront}
            />
            <span className={'top-winners-widget-item-gain-text text-center align-middle'}>
              {Math.trunc(secondWinner.gain) + ' pts'}
            </span>
          </div>
        </div>
      </div>
    )
  }

  const ThirdWinnerItem = ({ thirdWinner }: { thirdWinner: any }) => {
    return (
      <div className={'top-winners-widget-item flex justify-between flex-col items-center  w-full px-4 py-2'}>
        <div className={'top-winners-widget-item-info flex flex-col space-x-2 items-center '}>
          <div className={'top-winners-widget-item-profile flex justify-center items-center flex-col'}>
            <div
              className={
                'top-winners-widget-item-profile-country top-winners-widget-item-country bg-yellow-600 rounded-full p-1 w-16 h-16'
              }
            >
              <CountryIcon countryCode={thirdWinner.countryISOCode} />
            </div>
            <div
              className={
                'top-winners-widget-item-profile-medal relative w-8 h-8 bg-yellow-600 rounded-full flex justify-center items-center text-center p-2 -mt-4'
              }
            >
              <span className={'top-winners-widget-item-profile-text text-center text-white text-lg font-medium'}>
                3
              </span>
            </div>
          </div>
          <div className={'top-winners-widget-item-details flex justify-between flex-col items-center'}>
            <span
              className={
                'top-winners-widget-item-name text-lg text-center font-semibold capitalize block w-24 whitespace-nowrap overflow-hidden text-ellipsis'
              }
            >
              {thirdWinner.firstName}
            </span>
          </div>
          <div className={'top-winners-widget-item-gain flex justify-center items-center'}>
            <FontAwesomeIcon
              className={'top-winners-widget-item-gain-icon text-amber-400 mr-1 size-4'}
              icon={faCoinFront}
            />
            <span className={'top-winners-widget-item-gain-text text-center align-middle'}>
              {Math.trunc(thirdWinner.gain) + ' pts'}
            </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {topWinners?.length >= 3 && (
        <BaseWidget {...props}>
          <div
            className={`top-winners-widget relative overflow-hidden flex flex-col justify-center bg-card p-2 rounded-md border-[1px] border-neutral ${
              props.addPadding ? 'mx-2 md:mx-4' : ''
            }`}
          >
            <Image
              src={'/landing-page.png'}
              alt={'Top winners'}
              layout="fill"
              objectFit="cover"
              className="top-winners-widget-img rounded-md transition"
              placeholder="blur"
              blurDataURL={'/landing-page.png'}
            />

            <div
              className={
                'top-winners-widget-bg w-full h-full absolute top-0 left-0 bg-black bg-opacity-20 backdrop-blur-sm'
              }
            ></div>

            <div className={'top-three-winners grid grid-cols-3 items-end z-10'}>
              {secondWinner ? <SecondWinnerItem secondWinner={secondWinner} /> : null}
              {firstWinner ? <FirstWinnerItem firstWinner={firstWinner} /> : null}
              {thirdWinner ? <ThirdWinnerItem thirdWinner={thirdWinner} /> : null}
            </div>

            <div
              className={`top-winners-widget flex flex-col items-center text-cardContrast rounded-t-lg space-y-2.5 mt-2 z-10`}
            >
              {othersWinners?.map((topWinner, topWinnerIndex) => (
                <div
                  className="top-winners-widget-item flex justify-between items-center bg-gray-500 bg-opacity-10 w-full px-4 py-2 rounded-[4px] border-neutral"
                  key={topWinner.betSlipId}
                >
                  <div className="top-winners-widget-item-info flex space-x-4 items-center">
                    <span className={'w-5 text-center text-xs font-medium'}>{topWinnerIndex + 4}</span>
                    <div className="top-winners-widget-item-country bg-gray-500 bg-opacity-10 rounded-full w-8 h-8">
                      <CountryIcon countryCode={topWinner.countryISOCode} />
                    </div>
                    <span
                      className={
                        'top-winners-widget-item-name text-sm font-medium capitalize w-24 whitespace-nowrap overflow-hidden text-ellipsis'
                      }
                    >
                      {topWinner.firstName}
                    </span>
                  </div>
                  <span className="top-winners-widget-item-gain text-sm font-semibold">
                    {Math.trunc(topWinner.gain) + ' pts'}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </BaseWidget>
      )}
    </>
  )
}

export default RankingWidget
