import { Game, GameInfoFlags, Market, Odd, PreparedBets } from '@arland-bmnext/api-data'
import { useBetsClient, useOddsClient } from '../lib/api-client'
import { Session, buildSessionData } from '../lib/session'
import { BetSlipServiceBetSlipItem, BetTypes } from '../services/bet/bet.models'
import { ParsedUrlQuery } from 'querystring'
import { IncomingMessage } from 'http'

export const getSharedBet = async (
  req: IncomingMessage,
  session: Session,
  query: ParsedUrlQuery
): Promise<{
  sharedBet: PreparedBets
  sharedBetItems: BetSlipServiceBetSlipItem[]
}> => {
  let sharedBet: PreparedBets = null
  let sharedBetItems: BetSlipServiceBetSlipItem[] = []

  if (query?.referenceId) {
    try {
      const referenceId: string = query?.referenceId as string
      const betsClient = useBetsClient()
      const oddsClient = useOddsClient()
      const sessionId = session.get('sessionId')
      const token = session.get('token')
      const sessionData = buildSessionData(req, sessionId, token)

      let odd: Odd
      let game: Game
      let market: Market = null

      sharedBet = referenceId != null ? await betsClient.getSharedBet(sessionData, referenceId) : null

      for (const betSlip of sharedBet?.betSlips) {
        for (const item of betSlip.items) {
          game = await oddsClient.games.getGameById(sessionData, item.gameId, session.get('languageId'), [item.marketTypeId])
          if (game?.markets?.length === 0 && (game.infoFlags & GameInfoFlags.BetBuilder) === GameInfoFlags.BetBuilder) {
            market = await betsClient.getBetBuiilderMarket(sessionData, item.marketId, item.oddId)
          } else {
            market = game?.markets.find((market) => item.marketId == market?.id)
          }
          odd = market?.odds.find((odd) => item.oddId == odd?.id)
          sharedBetItems.push(new BetSlipServiceBetSlipItem(game, odd, market, item.type))
        }
      }
    } catch {
      sharedBet = null
      sharedBetItems = []
    }
  }

  return { sharedBet, sharedBetItems }
}

export const buildBetSlipDataFromSharedBet = (
  sharedBet: PreparedBets,
  sharedBetItems: BetSlipServiceBetSlipItem[]
): string => {
  if (sharedBet && sharedBetItems?.length != 0) {
    let selectedSharedBetType = null

    const sharedBetType =
      sharedBet.betSlips?.length == 1
        ? sharedBet.betSlips[0].systems[0].combination == sharedBet.betSlips[0].items?.length
          ? BetTypes.Combi
          : BetTypes.System
        : BetTypes.Single

    switch (sharedBetType) {
      case BetTypes.Combi:
        selectedSharedBetType = 'c' + sharedBet.betSlips[0].systems[0].combination
        break
      case BetTypes.System:
        selectedSharedBetType = 'y' + sharedBet.betSlips[0].systems[0].combination
        break
      case BetTypes.Single:
      default:
        selectedSharedBetType = 's' + sharedBet.betSlips[0].items[0].oddId
        break
    }

    const date = Date.now()

    const betslipData = JSON.stringify({
      stake: sharedBet.betSlips[0].stake,
      betSlipItems: sharedBetItems,
      betType: sharedBetType,
      selectedBetSystem: selectedSharedBetType,
      createdAt: date,
    })

    return betslipData
  }

  return null
}
