import { BonusType, GivenBonusListItem, GivenBonusState } from '@arland-bmnext/api-data'
import { faCheck, faCopy, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslation from 'next-translate/useTranslation'
import React, { useState } from 'react'
import useUserCurrency from '../../../hooks/useUserCurrency'
import { formatDateWithTime } from '../../../util/date'
import { EnumTranslator } from '../../../util/enum-translator'
import { formatMoney } from '../../../util/number'
import { ContainedButton } from '../../core/Button'
import Tag from '../../core/Tag'
import HelpText from '../../HelpText'
import { copyTextToClipboard } from '../../../util/clipboard'

export const BonusListItemSkeleton = ({ width = 'w-full' }) => {
  return (
    <div className={'bonus-list-item-skeleton flex flex-col space-y-2 p-3 rounded-lg skeleton ' + width}>
      <div className="flex justify-between">
        <span className="h-6 w-1/3 skeleton-content"></span>
        <span className="h-4 w-1/4 skeleton-content"></span>
      </div>
      <div className="flex justify-between">
        <span className="h-6 w-1/4 skeleton-content"></span>
        <span className="h-4 w-1/5 skeleton-content"></span>
      </div>
    </div>
  )
}

type BonusListItemProps = {
  bonus: GivenBonusListItem
  onAcceptBonus: (bonus: GivenBonusListItem) => Promise<void>
  onRejectBonus: (bonus: GivenBonusListItem) => Promise<void>
  hideBonusId?: boolean
}

export const BonusListItem = ({ bonus, onAcceptBonus, onRejectBonus, hideBonusId = false }: BonusListItemProps) => {
  const { t } = useTranslation('bonus')
  const currency = useUserCurrency()

  const [collapsed, setCollapsed] = useState(true)

  const getBonusAmountColor = () => {
    if (bonus?.state === GivenBonusState.Consumed || bonus?.state === GivenBonusState.TokenUsed) return 'bg-green-600'
    else if (bonus?.state === GivenBonusState.Rejected || bonus?.state === GivenBonusState.Cancelled)
      return 'bg-red-500'
    else if (bonus?.state === GivenBonusState.New) return 'bg-yellow-500'
    else if (bonus?.state === GivenBonusState.Expired) return 'bg-yellow-500'
    else return ''
  }

  return (
    <div className="bonus-list-item flex flex-col items-center">
      <div className="bonus-list-item-inner flex flex-col w-full space-y-3 p-3 bg-card text-cardContrast hover:bg-cardHover rounded-lg border-[1px] border-neutral">
        <div
          className="bonus-list-item-details flex flex-col space-y-3 w-full whitespace-nowrap cursor-pointer"
          onClick={() => setCollapsed(!collapsed)}
        >
          <div className="bonus-list-item-details-row flex space-x-2 justify-between items-center overflow-hidden">
            <div className="flex space-x-1 items-center overflow-hidden">
              <Tag
                label={EnumTranslator(t, GivenBonusState, bonus?.state)}
                backgroundColor={getBonusAmountColor()}
                textColor="text-white"
              />
              <span
                className={`font-bold overflow-hidden overflow-ellipsis ${
                  collapsed ? 'whitespace-nowrap' : 'whitespace-normal'
                }`}
              >
                {bonus?.bonusDisplayName}
              </span>
            </div>
            <div className="flex text-xs">{formatDateWithTime(bonus?.createdDate)}</div>
          </div>

          <div className="bonus-list-item-details-row flex justify-between">
            <div className="flex flex-col justify-end items-start">
              <span className="text-xs opacity-70">
                {bonus?.state === GivenBonusState.New && t('BonusPage.bonusAmount')}
              </span>
              <span className="text-sm font-bold">
                {formatMoney(bonus?.amount, currency)}
                {bonus?.bonusType === BonusType.FreeBet && <span> {t('BonusPage.freebet')}</span>}
              </span>
            </div>

            <div className="bonus-list-item-details-row flex flex-col items-end">
              <span className="text-xs opacity-70">
                <HelpText
                  displayLabel
                  title={t('BonusPage.blockedMultiplier.title')}
                  description={t('BonusPage.blockedMultiplier.description')}
                />
              </span>
              <span className="text-sm font-bold">{bonus?.campaignBlockedAmountMultiplier}x</span>
            </div>
          </div>
        </div>

        {bonus?.state === GivenBonusState.New && (
          <div className="bonus-list-item-actions flex space-x-3 mt-2">
            <ContainedButton
              onClick={() => onAcceptBonus(bonus)}
              backgroundColor="bg-green-600"
              borderColor="border-none"
              textColor="text-white"
              className="bonus-list-item-action-accept flex-grow"
              rounded
            >
              <FontAwesomeIcon icon={faCheck} />
              <span>{t('BonusPage.acceptBonus')}</span>
            </ContainedButton>

            <ContainedButton
              onClick={() => onRejectBonus(bonus)}
              backgroundColor="bg-red-500"
              borderColor="border-none"
              textColor="text-white"
              className="bonus-list-item-action-reject flex-grow"
              rounded
            >
              <FontAwesomeIcon icon={faTimes} />
              <span>{t('BonusPage.rejectBonus')}</span>
            </ContainedButton>
          </div>
        )}
      </div>

      {!collapsed && !hideBonusId && (
        <span className="bonus-list-item-id my-2 text-xs opacity-50">
          ID: {bonus?.id}
          <FontAwesomeIcon
            icon={faCopy}
            onClick={() => copyTextToClipboard(bonus?.id.toString(), t)}
            className="px-2 cursor-pointer"
          ></FontAwesomeIcon>
        </span>
      )}
    </div>
  )
}
