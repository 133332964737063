import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import React, { useEffect } from 'react'
import { isLoggedInCustomer, useUser, useUserAccounts } from '../../lib/user'
import BaseWidget, { BaseWidgetProps } from './BaseWidget'

import { Swiper, SwiperSlide } from 'swiper/react'
import { BonusListItem, BonusListItemSkeleton } from '../account/bonus/BonusListItem'
import { acceptBonus, rejectBonus, useOpenBonusCount, useOpenUserBonuses } from '../../lib/account'
import useCurrentLanguage from '../../hooks/useCurrentLanguage'
import { BookBonusResult, GivenBonusListItem, GivenBonusState } from '@arland-bmnext/api-data'

type OpenBonusWidgetProps = BaseWidgetProps & {
  orientation?: 'horizontal' | 'vertical'
  max_entries?: number
}

const OpenBonusWidget = (props: OpenBonusWidgetProps) => {
  const { t } = useTranslation('bets')
  const language = useCurrentLanguage()
  const { user } = useUser()
  const { mutateUserAccounts } = useUserAccounts()
  const { openBonuses, mutateOpenBonuses, isValidating } = useOpenUserBonuses(language?.id, props.max_entries)
  const { mutateOpenBonusCount } = useOpenBonusCount()

  const showSkeleton = isValidating && openBonuses == null

  useEffect(() => {
    mutateOpenBonuses()
  }, [user?.id])

  const ShowAllBonuses = () => {
    return (
      <Link href="/account/bonus" legacyBehavior>
        <span className="text-primary text-sm font-medium cursor-pointer hover:underline">
          {t('OpenBonusWidget.showAll')}
        </span>
      </Link>
    )
  }

  const onAcceptBonus = async (bonus: GivenBonusListItem) => {
    const res = await acceptBonus(bonus?.id)
    if (res === BookBonusResult.BonusConsumed) {
      const bonuses = [...openBonuses]
      bonuses.find((x) => x.id === bonus.id).state = GivenBonusState.Consumed

      await Promise.all([
        mutateUserAccounts(),
        mutateOpenBonusCount(),
        mutateOpenBonuses(
          {
            items: bonuses,
            totalCount: bonuses?.length,
          },
          false,
        ),
      ])
    }
  }

  const onRejectBonus = async (bonus: GivenBonusListItem) => {
    const res = await rejectBonus(bonus?.id)
    if (res === BookBonusResult.BonusRejected) {
      const bonuses = [...openBonuses]
      bonuses.find((x) => x.id === bonus.id).state = GivenBonusState.Rejected

      await Promise.all([
        mutateOpenBonusCount(),
        mutateOpenBonuses(
          {
            items: bonuses,
            totalCount: bonuses?.length,
          },
          false,
        ),
      ])
    }
  }

  return (
    <>
      {(showSkeleton || openBonuses?.length > 0) && isLoggedInCustomer(user) && (
        <BaseWidget
          className="open-bonus-widget"
          {...props}
          actionElement={<ShowAllBonuses />}
          title={props.title ?? t('OpenBonusWidget.title')}
        >
          {(props.orientation === 'horizontal' || props.orientation == null) && (
            <div className="open-bonus-widget-horizontal flex w-full overflow-hidden relative">
              {openBonuses?.length > 0 && (
                <Swiper className="open-bonus-widget-horizontal-swiper w-full" slidesPerView={'auto'} grabCursor>
                  {openBonuses?.map((bonus) => (
                    <SwiperSlide
                      key={bonus.id}
                      className={`open-bonus-widget-horizontal-swiper-slide w-[calc(100%-32px)] md:w-[calc(100%-40px)] max-w-[400px] md:first:ml-4 ml-2 last:mr-4`}
                    >
                      <BonusListItem
                        bonus={bonus}
                        onAcceptBonus={onAcceptBonus}
                        onRejectBonus={onRejectBonus}
                        hideBonusId
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}

              {showSkeleton && (
                <div className="flex items-start px-2 md:px-4 space-x-2 w-full overflow-hidden">
                  {[...Array(5)].map((_, index) => (
                    <BonusListItemSkeleton width="w-[calc(100%-32px)] max-w-[400px]" key={index} />
                  ))}
                </div>
              )}
            </div>
          )}

          {props.orientation === 'vertical' && (
            <div className="open-bonus-widget-vertical flex flex-col space-y-2">
              {openBonuses?.map((bonus) => (
                <BonusListItem bonus={bonus} onAcceptBonus={onAcceptBonus} onRejectBonus={onRejectBonus} hideBonusId />
              ))}

              {showSkeleton && [...Array(5)].map((_, index) => <BonusListItemSkeleton width="w-full" key={index} />)}
            </div>
          )}
        </BaseWidget>
      )}
    </>
  )
}

export default OpenBonusWidget
